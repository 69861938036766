export const data = {
  basic: [
    {
      name: "test",
      url: "https://www.google.com/search?q=imgbox&oq=imgbox&aqs=chrome..69i57j0i30i625l3j0i15i30j0i30j0i30i625j69i60.181j0j4&sourceid=chrome&ie=UTF-8",
      image:
        "https://naglfar28.oss-ap-southeast-1.aliyuncs.com/naglfar28/20230215222550.png",
      description: "",
    },
    {},
  ],
};
